import {signUpModal, openModal} from './popup';
import {getRedirectUrl, setCookie} from './helpers';
import {streamLobbyUrl} from './http.urls';
import {isUserLogged} from "./auth";

export function streamLobbyJoinBtn(container, streamLobby, isNewClient) {
  const gameId = streamLobby?.game_id;
  const priceData = isNewClient && streamLobby.new_customers
    ? streamLobby.new_customers_hour_price
    : streamLobby.hour_price;

  const streamPrice = isNewClient ? priceData / 2 : priceData;

  const getPrice = () => {
    return `$${streamPrice}${isNewClient ? '/30min' : '/h'}`
  }

  const btnNodes = container
    .querySelectorAll('.streamLobbyItemQueueButtonAdd');

  const joinBtn = btnNodes[0];

  joinBtn
    .querySelector('.join-button-price')
    .innerText = getPrice();

  joinBtn
    .addEventListener('click', function (e) {
      if (gameId) {
        setCookie('setGameAfterAuthorization', gameId.toString(), {secure: true, expires: 1/48})
      }

      amplitude.getInstance().logEvent('join_premade_click', { page: 'legionfarm_landing', streamLobby });

      if (isUserLogged()) {
        window.location.href = getRedirectUrl(streamLobbyUrl(streamLobby));
      } else {
        window.localStorage.setItem('autoJoinPremade', JSON.stringify(streamLobby));
        openModal(signUpModal);
      }
    })
}
