'use strict';

export function streamLobbyQueueSlot(container, streamLobby) {
  console.log()
  const slotsNumberForBuyers =   streamLobby
    .slots
    .filter((s) => s.type !== 'pro')
    .length;

  const freeSlots = slotsNumberForBuyers - streamLobby.slots.filter((s) => s.buyer !== null).length;

  container
    .querySelector('.queueStatus')
    .innerText = freeSlots;
}
