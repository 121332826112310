'use strict';

import {streamLobbyVideo} from './stream.lobby.video';
import {streamLobbyJoinBtn} from './stream.lobby.join.btn';
import {streamLobbyProCard} from './stream.lobby.pro.card';
import {streamLobbyDescription} from './stream.lobby.description';
import {streamLobbyQueueSlot} from './stream.lobby.queue.slot';
import {getCurrentActiveGameId} from "./helpers";
import {getPremades} from "./api";
import {API_URL} from "./constants";

const templateStreamLobbyCard = document.getElementById('stream-lobby-card');

const MOBILE_BREAKPOINT = 850;
const isMobile = window.innerWidth < MOBILE_BREAKPOINT;

export async function searchStreamLobby(requestFunction, accessToken) {
  const activeGameId = getCurrentActiveGameId();
  let header = document.querySelector('.streamLobby');
  let wrapper = document.querySelector('.premade-parties-wrapper');
  let container = document.getElementById('premade-parties-list');

  function mountStreamLobbyList(data, thumbnailPreview) {
    if (data?.data?.stream_lobby_list?.length > 0) {
      wrapper.classList.remove('hide');
      header.classList.remove('hide');
    } else {
      return
    }

    data.data.stream_lobby_list.forEach((streamLobby) => {
      let cloneTemplateStreamLobbyCard = templateStreamLobbyCard.content.cloneNode(true);

      const getHighlightOrStream = (streamLobby.slots[0]?.pro?.games?.[streamLobby.game_id]?.video_highlights_list[0]) ||
          (streamLobby.slots[1]?.pro?.games?.[streamLobby.game_id]?.video_highlights_list[0]) ||
          streamLobby.stream_url;

      streamLobbyVideo(cloneTemplateStreamLobbyCard, getHighlightOrStream, thumbnailPreview);
      streamLobbyDescription(cloneTemplateStreamLobbyCard, streamLobby, activeGameId);
      streamLobbyProCard(cloneTemplateStreamLobbyCard, streamLobby, activeGameId);
      streamLobbyQueueSlot(cloneTemplateStreamLobbyCard, streamLobby);
      streamLobbyJoinBtn(cloneTemplateStreamLobbyCard, streamLobby, data.data.is_new_customer);

      container.append(cloneTemplateStreamLobbyCard);
    });
  }

  let games = [{
    id: 24,
    stream_thumbnail: "https://staging-pwp-2.legionfarm.com/upload/images/stream/streamLobbyThumbnail.png",
    title: "COD: Warzone",
  }, {
    id: 11,
    stream_thumbnail: "https://staging-pwp-2.legionfarm.com/upload/images/stream/streamLobbyThumbnailApex.png",
    title: "Apex Legends",
  }]

  try {
    const gameList = await requestFunction(`${API_URL}/v2/app/game/list`);

    if (gameList) {
      games = gameList?.data?.games;
    }
  } catch (e) {
    console.error(e)
  }

  const getCurrentPreviewThumbnail = (gameId) => {
    return games.find((item) => {
      return item.id === parseInt(gameId, 10)
    }).stream_thumbnail;
  };

  try {
    const data = await getPremades(activeGameId, accessToken);
    mountStreamLobbyList(data, getCurrentPreviewThumbnail(activeGameId));
  } catch (e) {
    console.error(e)
  }
}
