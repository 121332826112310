'use strict';
import emptyAvatar from '../img-main-page/emptyAvatar.svg';
import winIcon from '../img-main-page/winIcon.svg';
import psIcon from '../img-main-page/psIcon.svg';
import xboxIcon from '../img-main-page/xboxIcon.svg';

const templateStreamLobbyItemProSlotWrapper = document.getElementById('stream-lobby-item-pro-slot-wrapper');
const templateStreamLobbyItemBuyerSlotWrapper = document.getElementById('stream-lobby-item-buyer-slot-wrapper');
const templateStreamLobbyItemEmptySlotWrapper = document.getElementById('stream-lobby-item-empty-slot-wrapper');
const templateStreamLobbyItemProSlotOptionWrapper = document.getElementById('stream-lobby-item-slot-option');

const FIELDS_FOR_STATS = ['Kills', 'ELO', 'KDA', 'Flawless', 'Winrate'];
const platformIcons = {
  win: winIcon,
  ps: psIcon,
  xbox: xboxIcon,
};

export function streamLobbyProCard(container, streamLobby, activeGameId) {
  const totalSlots = streamLobby?.slots?.length;
  const proSlots = streamLobby?.slots?.filter((s) => s?.type === 'pro').map((s) => s.pro);
  const buyerSlots = streamLobby?.slots?.filter((s) => s?.type === 'buyer' && s?.status === "busy").map((s) => s?.buyer);
  const emptySlots = totalSlots - (proSlots?.length + buyerSlots?.length);

  const slotsWrapper = container.querySelector('.streamLobbyItemSlotsWrapper');
  const nextButton = container.querySelector('.customSliderNextBtn');
  const prevButton = container.querySelector('.customSliderPrevBtn');

  const getPlatformIcon = (platformValue) => {
      return platformIcons?.[platformValue];
  }

  const swiperSlots = new Swiper(slotsWrapper, {
    slidesPerView: 3,
    spaceBetween: 16,
    speed: 300,
    navigation: {
      nextEl: nextButton,
      prevEl: prevButton,
    },
    breakpoints: {
      350: {
        slidesPerView: 1.5,
        spaceBetween: 8,
      },
      590: {
        slidesPerView: 3,
        spaceBetween: 16,
      }
    }
  });

  proSlots
    .reverse()
    .forEach((pro) => {
      const country = pro?.country;
      const description = pro?.description;
      const rating = pro?.rating_json?.avg;
      const KDA = pro?.games?.[activeGameId]?.stats?.kda;
      const Kills = pro?.games?.[activeGameId]?.stats?.kills;
      const ELO = pro?.games?.[activeGameId]?.stats?.elo;
      const Winrate = pro?.games?.[activeGameId]?.stats?.winrate;
      const Flawless = pro?.games?.[activeGameId]?.stats?.flawless;

      const mainPlatform = pro?.games?.[activeGameId]?.platforms?.find(i => i?.is_main_platform)?.value;

      let optionslist;

      if (activeGameId === 100) {
        optionslist = {
          country,
          mainPlatform,
          ELO,
          KDA,
          Winrate,
          Flawless,
        }
      } else {
        optionslist = {
          country,
          mainPlatform,
          KDA,
          Kills
        }
      }

      let cloneTemplateStreamLobbyItemProSlotWrapper = templateStreamLobbyItemProSlotWrapper
        .content
        .cloneNode(true);

      cloneTemplateStreamLobbyItemProSlotWrapper
        .querySelector('.streamLobbyItemPlayerAvatar').src = pro?.avatar || emptyAvatar;

      cloneTemplateStreamLobbyItemProSlotWrapper
        .querySelector('.streamLobbyProRank').innerText = pro?.rank_name;

      cloneTemplateStreamLobbyItemProSlotWrapper
        .querySelector('.streamLobbySlotDescription').innerText = description;

      if (rating) {
        cloneTemplateStreamLobbyItemProSlotWrapper
          .querySelector('.streamLobbyProRating').innerText = rating;
      } else {
        cloneTemplateStreamLobbyItemProSlotWrapper
          .querySelector('.streamLobbyProRatingBlock')
          .classList.add('d-none')
      }

      cloneTemplateStreamLobbyItemProSlotWrapper
        .querySelector('.streamLobbyItemProNickname').innerText = pro.nickname;

      Object.keys(optionslist).forEach((optionKey) => {
          let cloneTemplateStreamLobbyItemProSlotOptionWrapper = templateStreamLobbyItemProSlotOptionWrapper
            .content
            .cloneNode(true);

          if (FIELDS_FOR_STATS.includes(optionKey)) {
              cloneTemplateStreamLobbyItemProSlotOptionWrapper
                .querySelector('.streamLobbySlotTag').innerText =
                    `${optionKey.toString()}: ${optionslist?.[optionKey]}`;
          } else if (optionKey === 'mainPlatform') {
            const platformImage = new Image();
            const platformValue = optionslist?.[optionKey]?.toString()?.toLowerCase();

            if (platformValue && platformIcons.hasOwnProperty(platformValue)) {
              platformImage.src = getPlatformIcon(platformValue);

              cloneTemplateStreamLobbyItemProSlotOptionWrapper
                .querySelector('.streamLobbySlotTag').append(platformImage);
            } else {
              return;
            }
          } else {
            cloneTemplateStreamLobbyItemProSlotOptionWrapper
              .querySelector('.streamLobbySlotTag').innerText = optionslist?.[optionKey];
          }

        cloneTemplateStreamLobbyItemProSlotWrapper
            .querySelector('.streamLobbySlotTagsWrapper')
            .append(cloneTemplateStreamLobbyItemProSlotOptionWrapper);
      })

      container
        .querySelector('.streamLobbyItemSlotsWrapper')
        .querySelector('.slotsWrapper')
        .prepend(cloneTemplateStreamLobbyItemProSlotWrapper);
    })


  buyerSlots
    .reverse()
    .forEach((buyer) => {
      let cloneTemplateStreamLobbyItemBuyerSlotWrapper = templateStreamLobbyItemBuyerSlotWrapper
        .content
        .cloneNode(true);

      const country = buyer?.country?.title;
      const platform = buyer?.platform;

      const optionslist = {
        country,
        platform,
      }

      Object.keys(optionslist).forEach((option) => {
        let cloneTemplateStreamLobbyItemProSlotOptionWrapper = templateStreamLobbyItemProSlotOptionWrapper
          .content
          .cloneNode(true);

        if (!!optionslist?.[option]) {
          if (option === 'platform') {
            const platformImage = new Image();
            const platformValue = optionslist?.[option]?.toString()?.toLowerCase();

            if (platformValue && platformIcons.hasOwnProperty(platformValue)) {
              platformImage.src = getPlatformIcon(platformValue);

              cloneTemplateStreamLobbyItemProSlotOptionWrapper
                .querySelector('.streamLobbySlotTag')
                .append(platformImage);
            } else {
              return
            }
          } else {
            cloneTemplateStreamLobbyItemProSlotOptionWrapper
              .querySelector('.streamLobbySlotTag')
              .innerText = optionslist?.[option];
          }

          cloneTemplateStreamLobbyItemBuyerSlotWrapper
            .querySelector('.streamLobbySlotTagsWrapper')
            .append(cloneTemplateStreamLobbyItemProSlotOptionWrapper);
        }
      });

      cloneTemplateStreamLobbyItemBuyerSlotWrapper
        .querySelector('.streamLobbyItemPlayerAvatar').src = buyer?.avatar || emptyAvatar;

      cloneTemplateStreamLobbyItemBuyerSlotWrapper
        .querySelector('.streamLobbyItemProNickname').innerText = buyer?.nickname;

      container
        .querySelector('.streamLobbyItemSlotsWrapper')
        .querySelector('.slotsWrapper')
        .append(cloneTemplateStreamLobbyItemBuyerSlotWrapper);
    })

    if (!!emptySlots) {
      const emptySlotsArr = Array.from({length: emptySlots}, (_, index) => index + 1);

      emptySlotsArr
        .forEach(() => {
          let cloneTemplateStreamLobbyItemEmptySlotWrapper = templateStreamLobbyItemEmptySlotWrapper
            .content
            .cloneNode(true);

          container
            .querySelector('.streamLobbyItemSlotsWrapper')
            .querySelector('.slotsWrapper')
            .append(cloneTemplateStreamLobbyItemEmptySlotWrapper);
        })

    }
}
