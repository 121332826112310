'use strict';

import {GAME_IDS} from "./constants";

export function streamLobbyDescription(container, streamLobby, activeGameId) {
    const gameFormat = streamLobby.game_format;
    const serverName = streamLobby.server_name;
    const isPrivateLobby = streamLobby?.is_streaming;

    if (activeGameId === GAME_IDS.warzone) {
      container.querySelector('.streamLobbyItemDescription').innerHTML = `<span>Warzone: </span>${streamLobby.description}`;
        return
    }
    if (activeGameId === GAME_IDS.warzone2) {
      container.querySelector('.streamLobbyItemDescription').innerHTML = `<span>Warzone 2.0: </span>${streamLobby.description}`;
        return
    } else{
      container
          .querySelector('.streamLobbyItemDescription')
          .innerText = streamLobby.description;
    }

    if (isPrivateLobby) {
        container
            .querySelector('.streamLobbyPremadeType')
            .innerText = `Stream`;
    }

    container
        .querySelector('.serverAndGameFormat')
        .innerText = `Playing in ${gameFormat} on ${serverName} server`;
}
