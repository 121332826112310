import {getCookie, getRedirectUrl} from "../helpers";
import {LF2_URL, LF2_MARK, cookieAttributes, pwpAccess, CLIENT_MAIN_PAGE} from "../constants";
import {checkUsersCredentials, isUserLogged} from "../auth";
import {searchStreamLobby} from "../search.stream.lobby";
import {httpRequest} from "../http.request";

export const redirectToLF2 = () => {
  window.location.href = LF2_URL;
}

async function main() {
  await checkUsersCredentials();

  const accessToken = getCookie(pwpAccess);
  await searchStreamLobby(httpRequest(), accessToken);
  window.localStorage.removeItem('coaching');
  if (isUserLogged()) {
    window.location.href = getRedirectUrl(CLIENT_MAIN_PAGE);
  }
}

// entry point for base landing logic
main();


const scrollButton = document.querySelector('.play-button');
scrollButton.addEventListener('click', () => {
  window.scrollTo({
    top: 1200,
    behavior: 'smooth'
  });
})